import { useAuthState, Loading, Title } from 'react-admin'
import { redirect } from 'react-router'
import { Card, CardContent, Typography } from '@mui/material'
import classes from './Dashboard.module.sass'
import useDashboardHook from './hooks/useDashboardHook'
import { DeviceItemTable } from './components/deviceItemTable/DeviceItemTable'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export const Dashboard = () => {
  const {
    deviceStatusList,
    isDataLoading,
    toggleDebug,
    playScene,
    updateDeviceConfig,
    rebootDevice,
    startScenario,
    stopScenario,
    playNextScene,
    snackbarOpen,
    closeSnackbar,
    snackbarMessage,
    snackbarError,
    rotateDisplay,
  } = useDashboardHook()
  /** This hook is secure access to the page */
  const { isLoading, authenticated } = useAuthState()

  if (isLoading) {
    return <Loading />
  }
  if (authenticated) {
    return (
      <div className={classes.Container}>
        <Title title="Главная" />
        <Typography variant="h6" gutterBottom>
          Устройства в сети:
        </Typography>
        {isDataLoading && <Loading />}
        {!isDataLoading &&
          deviceStatusList.map((item, index) => (
            <DeviceItemTable
              deviceConfig={item}
              toggleDebug={toggleDebug}
              playScene={playScene}
              updateDeviceConfig={updateDeviceConfig}
              rebootDevice={rebootDevice}
              startScenario={startScenario}
              stopScenario={stopScenario}
              playNextScene={playNextScene}
              rotateDisplay={rotateDisplay}
              key={index}
            />
          ))}
        {!isDataLoading && deviceStatusList.length === 0 && (
          <Typography variant="subtitle1" gutterBottom>
            список пуст
          </Typography>
        )}
        {/*<Snackbar*/}
        {/*  open={snackbarOpen}*/}
        {/*  autoHideDuration={6000}*/}
        {/*  onClose={closeSnackbar}*/}
        {/*  message={snackbarMessage}*/}
        {/*  action={*/}
        {/*    <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackbar}>*/}
        {/*      <CloseIcon fontSize="small" />*/}
        {/*    </IconButton>*/}
        {/*  }*/}
        {/*/>*/}
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert
            onClose={closeSnackbar}
            severity={snackbarError ? 'error' : 'success'}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
    )
  }
  if (!authenticated) redirect('/login')
  return null
}
