import { DeviceState, ScenarioState } from '../../interfaces'
import classes from './DeviceItemTable.module.sass'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import PestControlIcon from '@mui/icons-material/PestControl'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import FastForwardIcon from '@mui/icons-material/FastForward'
import useDeviceItemTableHook from './hooks/useDeviceItemTableHook'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'

type Props = {
  deviceConfig: DeviceState
  toggleDebug: (deviceId: string, value: boolean) => void
  playScene: (deviceId: string, sceneId: string) => void
  updateDeviceConfig: (deviceId: string) => void
  rebootDevice: (deviceId: string) => void
  startScenario: (deviceId: string) => void
  stopScenario: (deviceId: string) => void
  playNextScene: (deviceId: string) => void
  rotateDisplay: (deviceId: string, angle: number) => void
}

export const DeviceItemTable = ({
  deviceConfig,
  toggleDebug,
  playScene,
  updateDeviceConfig,
  rebootDevice,
  startScenario,
  stopScenario,
  playNextScene,
  rotateDisplay,
}: Props) => {
  const { activeScenes, open, setOpen, getDuration } = useDeviceItemTableHook({
    deviceConfig,
  })

  return (
    <div className={classes.Container}>
      <Card>
        <CardHeader
          action={
            <>
              <Tooltip title="перезагрузить">
                <IconButton aria-label="reboot" onClick={() => rebootDevice(deviceConfig.deviceId)}>
                  <RestartAltIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="обновить конфигурацию">
                <IconButton
                  aria-label="updateConfig"
                  onClick={() => updateDeviceConfig(deviceConfig.deviceId)}
                >
                  <BrowserUpdatedIcon color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="повернуть экран влево">
                <IconButton
                  aria-label="rotate-left"
                  disabled={!deviceConfig.displayInfo}
                  onClick={() => {
                    rotateDisplay(
                      deviceConfig.deviceId,
                      (deviceConfig.displayInfo?.transform ?? 0) - 90,
                    )
                  }}
                >
                  <UndoIcon color={deviceConfig.displayInfo ? 'primary' : 'disabled'} />
                </IconButton>
              </Tooltip>
              <Tooltip title="повернуть экран вправо">
                <IconButton
                  aria-label="rotate-right"
                  disabled={!deviceConfig.displayInfo}
                  onClick={() => {
                    rotateDisplay(
                      deviceConfig.deviceId,
                      (deviceConfig.displayInfo?.transform ?? 0) + 90,
                    )
                  }}
                >
                  <RedoIcon color={deviceConfig.displayInfo ? 'primary' : 'disabled'} />
                </IconButton>
              </Tooltip>
            </>
          }
          title={
            <Typography color="text.primary" gutterBottom>
              {deviceConfig.deviceName}
            </Typography>
          }
          subheader={
            <>
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                ver: <span style={{ fontWeight: 'bold' }}>{deviceConfig.vb}</span>
              </Typography>
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                local ip address: <span style={{ fontWeight: 'bold' }}>{deviceConfig.localIp}</span>
              </Typography>
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                воспроизведение:{' '}
                {deviceConfig.isPlaying ? (
                  <span style={{ color: 'green' }}>включено</span>
                ) : (
                  <span style={{ color: 'red' }}>выключено</span>
                )}
              </Typography>
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                монитор:{' '}
                {deviceConfig.isMonitorConnected ? (
                  <span style={{ color: 'green' }}>подключен</span>
                ) : (
                  <span style={{ color: 'red' }}>не подключен</span>
                )}
              </Typography>
              {deviceConfig.displayInfo && (
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  └ название:{' '}
                  <span style={{ fontWeight: 'bold' }}>{deviceConfig.displayInfo?.name}</span>
                </Typography>
              )}
              {deviceConfig.displayInfo && (
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  └ поворот экрана:{' '}
                  <span style={{ fontWeight: 'bold' }}>{deviceConfig.displayInfo?.transform}</span>
                </Typography>
              )}
              {deviceConfig.displayInfo && (
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  └ разрешение:{' '}
                  <span style={{ fontWeight: 'bold' }}>{deviceConfig.displayInfo?.resolution}</span>
                </Typography>
              )}
              {deviceConfig.displayInfo && (
                <Typography sx={{ fontSize: 12 }} color="text.secondary">
                  └ частота обновления:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {deviceConfig.displayInfo?.refreshRate}
                  </span>
                </Typography>
              )}
            </>
          }
        />
      </Card>
    </div>
  )
}
